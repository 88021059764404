.App-header {
  background-image: url(/images/hero.jpg), linear-gradient(90deg,rgba(0,0,0,0.53) 0%,rgba(41,196,169,0) 82%) !important;
  background-size:cover;
  background-origin: padding-box, padding-box;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: white;
  height: 700px;
  padding: 200px 70px;
}

.main-slogan{
  font-family: 'Roboto',Helvetica,Arial,Lucida,sans-serif;
}